<template>
	<div>
		<label>Append conflict value to:</label>
		<b-form-select
			:value="options.conflictField"
			:options="conflictFieldOptions"
			@change="e => $store.commit('options/setConflictField', e)"
		></b-form-select>

		<hr />

		<b-row class="mb-4">
			<b-col cols="7">
				Included Articles ({{
				refs
					.filter(el => el.include).length
				}})
			</b-col>
			<b-col><b-button variant="success" @click="downloadIncluded">Save</b-button></b-col>
		</b-row>

		<b-row class="mb-4">
			<b-col cols="7">
				Discarded Articles ({{
				refs
					.filter(el => !el.include).length
				}})
			</b-col>
			<b-col><b-button @click="downloadDiscarded">Save</b-button></b-col>
		</b-row>

	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { conflictFieldOptions } from "../types";

import exportMixin from "../mixins/exportMixin";

export default {
	name: "ExportModal",
	mixins: [exportMixin],
	computed: {
		...mapState({
			options: state => state.options
		}),
		...mapGetters({
			refs: 'references/getRefs'
		})
	},
	data() {
		return {
			conflictFieldOptions: conflictFieldOptions
		}
	},
	methods: {
		log(e) {
			console.log(e);
		}
	}
}
</script>
